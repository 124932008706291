<template>
  <div>
    <div class="control" style="display:flex;align-items:center;margin-bottom:15px;">
    <label class="label" style="display:block;margin-bottom:0;margin-right:15px;">{{ $t('constructor["Link"]') }}</label>
      <v-text-field
        outlined
        dense
        hide-details
        :placeholder="$t('constructor[\'Link\']')"
        v-model="content.content"
      ></v-text-field>
    </div>
    <div class="control" style="font-weight:bold;margin:15px 0;">OR</div>
    <div class="control" style="">
      <label class="label">You can add link to file here</label>
      <vue-dropzone
        style="height:250px;"
        id="dropzoneFile"
        ref="dropzoneFile"
        @vdropzone-mounted="mountDropzoneFile(content.content)"
        :options="dropzoneOptionsFile"
        @vdropzone-sending="customEventFile"
        @vdropzone-max-files-exceeded="maxFileSexceeded"
        v-on:vdropzone-removed-file="(file) => handleRemoveFile(file, content)"
        @vdropzone-success="(file) => handleSuccessFile(file, content)"
      >
      </vue-dropzone>
    </div>
  </div>
</template>

<script>
import vueDropzone from "vue2-dropzone";
import { mapState } from 'vuex';
export default {
  components: {
    vueDropzone,
  },
  name: "typeLink",
  props: ["content"],
  data() {
    return {
      dataCount:0,
      dropzoneOptionsFile: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-file";
        },
        thumbnailMethod: "contain",
        maxFiles: 1,
        addRemoveLinks: true,
      },
    };
  },
  mounted(){
    this.dataCount += 1;
  },
  methods: {
    getFileExtension(filename) {
      if(filename){
      const extension =
        filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
        filename;
      return extension;
      }else{
        return ""
      }
    },
    maxFileSexceeded(file) {
      this.$refs.dropzoneFile.removeAllFiles();
      this.$refs.dropzoneFile.addFile(file);
    },
    customEventFile(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append("hotel_id", this.$route.params.id);
      formData.append("file", _file);
    },
    handleRemoveFile(file, content) {
      content.content = "";
      this.setMenu();
    },
    mountDropzoneFile(img) {
      if (this.getFileExtension(img)&&this.getFileExtension(img).includes('mobi')) {
        const file = {
          name: "Icon",
          type: "image/jpeg",
          dataURL: process.env.VUE_APP_LINK+"/file.png",
        };
        this.$refs.dropzoneFile.manuallyAddFile(
          file,
          process.env.VUE_APP_LINK+"/file.png"
        );
        this.$refs.dropzoneFile.dropzone.emit(
          "thumbnail",
          file,
          file.dataURL
        );
        this.$refs.dropzoneFile.dropzone.emit("complete", file);
      }
    },
    setMenu() {
      this.$store.commit("landing/setMenu", this.menu);
    },
    handleSuccessFile(file, content) {
      let link = file.xhr.response.replaceAll("\\", "").replaceAll('"', "");
      content.content = link;
      document.getElementsByClassName("dz-image")[0].style.background =
        "transparent";
      document
        .getElementsByClassName("dz-image")[0]
        .getElementsByTagName("img")[0].src = process.env.VUE_APP_LINK+"/file.png";
      document.getElementsByClassName("dz-details")[0].style.background =
        "transparent";
    },
  },
  computed:{
    ...mapState('landing',['menu'])
  }
};
</script>

<style lang="scss" scoped></style>
