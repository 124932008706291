<template>
  <div>
    <div v-if="roomCategories.length">
      <div v-if="landing.current_lang !== landing.advanced.default_lang" style="margin-bottom:15px;display:flex;justify-content:space-between;align-items:center;">
        <label class="label">Add dynamic buttons to your page</label>

        <v-menu offset-y open-on-hover bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark outlined small v-bind="attrs" v-on="on">
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title
                  style="cursor: pointer" @click="copyLang"
              >Copy content from {{landing.advanced.default_lang}}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-btn
          v-if="!$store.state.dynamicFlipped"
        style="
        margin-left: auto;
        display: flex;
        margin-top: 10px;
        "
        class="mb-4"
        color="primary"
        outlined
        @click="addDynamicContent"
        small
      >
        + Add dynamic content
      </v-btn>
      <div
        v-for="(contentItem, index) in content.content"
        :key="index + 'groupDynamic'"
      >
        <div
          v-if="!$store.state.dynamicFlipped"
          class="control"
          style="padding:15px;border:1px solid rgb(238, 238, 238);border-radius:5px;margin-bottom:15px;"
        >
          <div style="display:flex;flex-direction:column;">
            <div
              style="display:flex;justify-content:space-between;align-items:center;margin-bottom:15px;"
            >
            <label class="label">Dynamic logical block № {{index+1}}</label>
            <div>
              <v-menu offset-y open-on-hover bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark outlined small v-bind="attrs" v-on="on">
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title @click="showFlipped(contentItem, index)"
                        style="cursor: pointer"
                    >Open content</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item :disabled="content.default === index">
                    <v-list-item-title style="cursor: pointer"  @click="deleteDynamicButton(index)"
                    >Delete</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="display:block;margin-right:15px;margin-bottom:0;">Change rooms for show:</label>
              <v-combobox
                  hide-details
                  v-model="contentItem.filter"
                  class="canvas__select"
                  item-text="name"
                  item-value="id"
                  :items="availlableCategories"
                  multiple
                  outlined
                  dense
                  flat
              >
                <template v-slot:selection="{ item }">
                  {{ item.name + ", " }}
                </template>
                <template v-slot:item="{ item }">
                  {{ item.name }}
                </template>
              </v-combobox>
            </div>
          </div>
          <div style="display:flex;align-items:center;">
            <label class="label" style="min-width:200px;">Use as default</label>
            <v-checkbox @click="changeDefault(index)" hide-details :input-value="content.default === index" :disabled="content.default === index" > </v-checkbox>
          </div>
          <div style="display:flex;align-items:center;">
              <v-btn
                color="primary"
                outlined
                small
                style="
                  color: #007db5;
                  cursor: pointer;
                  margin-top: 15px;
                "  @click="showFlipped(contentItem, index)">
                Content settings
              </v-btn>
          </div>
        </div>
      </div>
      <div class="control" v-if="$store.state.dynamicFlipped">
        <div style="margin-bottom:15px;">

          <v-btn color="primary" outlined @click="hideFlipped" small
          ><v-icon>mdi-chevron-left</v-icon>
            <span
            >Back to content types</span
            ></v-btn
          >
        </div>
            <div
                style="padding: 15px;border:1px solid rgb(238,238,238);border-radius:5px;margin-bottom:15px;" v-if="selectedContent.type === 0">
              <typeHtml :item="item" :content="selectedContent.content[0]" />
            </div>
            <div
                style="padding: 15px;border:1px solid rgb(238,238,238);border-radius:5px;margin-bottom:15px;" v-if="selectedContent.type === 1">
              <typeLink :content="contentDynamic.content[1]" />
            </div>
            <div
                style="padding: 15px;border:1px solid rgb(238,238,238);border-radius:5px;margin-bottom:15px;" v-if="selectedContent.type === 2">
              <typeListing
                :item="item"
                :content="contentDynamic.content[2]"
                :menuType="'menu'"
              />
            </div>
            <div
                style="padding: 15px;border:1px solid rgb(238,238,238);border-radius:5px;margin-bottom:15px;" v-if="selectedContent.type === 3">
              <typeGallery
                  :item="item"
                  :content="contentDynamic.content[3]"
              />
            </div>
      </div>
    </div>
    <div v-else style="margin:25px 0 40px 0;">
      You can't use dynamic type without any room, please create them
      <span
        style="color:#007db5;cursor:pointer;"
        @click="openRoute('rooms', $route.params.id, $route.params.lang)"
      >
        here
      </span>
    </div>
    <PopupDelete  ref="PopupDelete" @confirm="confirmDelete" :title="`Are you sure about delete logical block № ${deleteIndex+1}`"/>
    <PopupSuccess  ref="PopupSuccess" :title="'Thank You!'" :content="successMessage"/>
    <PopupConfirm
        ref="PopupConfirm"
        @confirm="confirmCopy"
        :title="
        `Are you sure about copy content from ${defaultLangName} to ${currentLangName}`
      "
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import typeHtml from "./typeHtml.vue";
import typeLink from "./typeLink.vue";
import typeListing from "./typeListing.vue";
import typeGallery from "./typeGallery.vue"
import defaults, {defaultFormMenuItem, defaultListingStyles} from "@/store/landing.js";
import PopupSuccess from "@/components/Popup/PopupSuccess.vue"
import PopupDelete from "@/components/Popup/PopupDelete.vue"
import PopupConfirm from "@/components/Popup/PopupConfirm";
export default {
  name: "typeDynamic",
  props: ["item", "content"],
  components: {
    typeHtml,
    typeLink,
    typeListing,
    PopupDelete,
    PopupSuccess,
    typeGallery,
    PopupConfirm,
  },
  data() {
    return {
      deleteIndex:null,
      successMessage:"",
      flipped: false,
      flippedIndex: null,
      dynamicTypes: [
        {
          id: 1,
          type: "html",
          text: "HTML",
        },
        {
          id: 2,
          type: "link",
          text: this.$t('constructor["Link"]'),
        },
        {
          id: 3,
          type: "listing",
          text: "Listing",
        },
        {
          id: 4,
          type: "gallery",
          text: "Gallery",
        },
      ],
    };
  },
  async mounted() {
    await this.getRoomCategories(this.$route.params.id);
  },
  computed: {
    ...mapState("rooms", ["roomCategories", "selectedContent"]),
    ...mapState(['landing']),
    selectedCategories() {
      return this.content.content.reduce((acc, item) => {
        acc = [...acc, ...item.filter];
        return acc;
      }, []);
    },
    availlableCategories() {
      return this.roomCategories.reduce((acc, item) => {
        const candidate = this.selectedCategories.find((x) => x.id === item.id);
        if (!candidate) {
        let obj = {
          name:item.name,
          id:item.id
        }
          acc.push(obj);
        }
        return acc;
      }, []);
    },
    currentLangName() {
      return this.landing.advanced.langs.find(
          (x) => x.value === this.landing.current_lang
      ).name;
    },
    defaultLangName() {
      return this.landing.advanced.langs.find(
          (x) => x.value === this.landing.advanced.default_lang
      ).name;
    },
  },
  methods: {
    ...mapActions("rooms", ["getRoomCategories"]),
    copyLang(){
      this.$refs.PopupConfirm.open();
    },
    confirmCopy(){
      this.item.content[this.landing.current_lang][11] = JSON.parse(JSON.stringify(this.item.content[this.landing.advanced.default_lang][11]));
      this.forceRerender();
    },
    forceRerender() {
      // Removing my-component from the DOM
      this.$store.state.renderComponent = false;

      this.$nextTick(() => {
        // Adding the component back in
        this.$store.state.renderComponent = true;
      });
    },
    deleteDynamicButton(index){
      this.deleteIndex = index;
      this.$refs.PopupDelete.open();
    },
    confirmDelete(){
      this.content.content.splice(this.deleteIndex,1);
      this.successMessage = "Deleted successfull!";
      this.$refs.PopupSuccess.open();
    },
    changeDefault(index) {
        this.content.default = index;
    },
    addDynamicContent() {
      this.content.content.push({
        filter: [],
        type: 0,
        content: [
          {
            type: 1,
            content:
                "<p>Blank page, but you can add something interesting to it!</p>",
            slider: [
              {
                id: 0,
                title: "Example title",
                slider_view_all: {
                  show: false,
                  text: "View all",
                  link: "",
                },
                hide: false,
                show_type: "bottom",
                border_color: "#cccccc",
                type: "horizontal",
                settings: false,
                vertical: {
                  height: "220",
                  width: "150",
                  img_height: "150",
                },
                horizontal: {
                  height: "150",
                  width: "250",
                  img_width: "100",
                },
                background: "rgba(255,255,255,1)",
                bg_group: "rgba(255,255,255,1)",
                radius: 0,
                slides: [
                  {
                    img: require("@/assets/none.png"),
                    text: "Example first block",
                    text_link: null,
                    useLink: false,
                    link: null,
                  },
                ],
              },
            ],
            active_slider: false,
            form: JSON.parse(
                JSON.stringify(defaultFormMenuItem)
            ),
          },
          {
            type: 2,
            content: "",
          },
          {
            type: 3,
            styles: JSON.parse(
                JSON.stringify(defaultListingStyles)
            ),
            content: {
              listing: [
                {
                  title: "default listing title",
                  hide: true,
                  content: [
                    {
                      img: [],
                      video_link: null,
                      name: "default name",
                      descr: "default description",
                      main_img: "",
                      preview: false,
                      full_descr:
                          "default full description",
                      price: "100",
                      show_price: true,
                      show_descr: true,
                      hide: true,
                      name_color: "#000000",
                      lining_padding: 15,
                      position: "center center",
                      background_color:
                          "rgba(255,255,255,1)",
                      descr_color: "#000000",
                      show_name_color: false,
                      show_descr_color: false,
                      id: 1,
                      form: JSON.parse(
                          JSON.stringify(
                              defaultFormMenuItem
                          )
                      ),
                    },
                  ],
                },
              ],
            },
            active_slider: false,
            slider: [
              {
                id: 0,
                title: "Example title",
                slider_view_all: {
                  show: false,
                  text: "View all",
                  link: "",
                },
                settings: false,
                hide: false,
                show_type: "bottom",
                border_color: "#cccccc",
                type: "horizontal",
                vertical: {
                  height: "220",
                  width: "150",
                  img_height: "150",
                },
                horizontal: {
                  height: "150",
                  width: "250",
                  img_width: "100",
                },
                background: "rgba(255,255,255,1)",
                bg_group: "rgba(255,255,255,1)",
                radius: 0,
                slides: [
                  {
                    img: require("@/assets/none.png"),
                    text: "Example first block",
                    text_link: null,
                    useLink: false,
                    link: null,
                  },
                ],
              },
            ],
          },
          {
            type: 4,
            img: [],
            video_link: null,
            preview: false,
            full_desc: "default description",
            slider: [
              {
                id: 0,
                title: "Example title",
                slider_view_all: {
                  show: false,
                  text: "View all",
                  link: "",
                },
                settings: false,
                hide: false,
                show_type: "bottom",
                border_color: "#cccccc",
                type: "horizontal",
                vertical: {
                  height: "220",
                  width: "150",
                  img_height: "150",
                },
                horizontal: {
                  height: "150",
                  width: "250",
                  img_width: "100",
                },
                background: "rgba(255,255,255,1)",
                bg_group: "rgba(255,255,255,1)",
                radius: 0,
                slides: [
                  {
                    img: require("@/assets/none.png"),
                    text: "Example first block",
                    text_link: null,
                    useLink: false,
                    link: null,
                  },
                ],
              },
            ],
            styles: {
              img_height: 200,
              border_color: "#eee",
              border_size: 0,
              position: "center center",
              shadow_size: 0,
              shadow_color: "#eee",

              fontFamily: "Inter",
            },
            form: JSON.parse(
                JSON.stringify(defaultFormMenuItem)
            ),
          }
        ],
      });
    },
      async openRoute(name, id, lang) {
      await this.$router.push({
        name: name,
        query: { object: id },
        params: { lang: lang },
      });
    },
    showFlipped(item, index) {
      this.$store.state.dynamicIndex = index;
      this.$store.state.rooms.selectedContent = item;
      this.$store.state.dynamicFlipped = true;
    },
    hideFlipped() {
      this.$store.state.dynamicFlipped = false;
      this.$store.state.rooms.selectedContent = null;
      this.$store.state.dynamicIndex = null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
